/* styles.css */

@keyframes waveAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.card-header.animated-text {
  background-image: linear-gradient(
    90deg,
    rgba(238, 200, 174, 1) 0%,
    rgba(221, 34, 34, 0.8379726890756303) 72%
  );
  position: relative;
  text-align: center; /* Center the text horizontally */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px; /* Adjust the height to your preference */
}

.animated-text span {
  animation: waveAnimation 2s infinite;
}
